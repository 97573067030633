<template lang="">
  <div class="d-flex flex-column justify-space-between align-start compliment rounded-lg py-4 px-3 admin-message">
    <div v-if="!m.opened" class="notification success lighten-1">
      <v-icon small class="smoke--text ma-0 pa-0">mdi-bell</v-icon>
    </div>
    <div class="text-h6 full-width mb-2">
      <v-icon class="mr-2" small>mdi-account</v-icon>
      {{m.name}}
    </div>
    <div class="text-subtitle-2 full-width mb-2">
      <v-icon class="mr-2" small>mdi-email</v-icon>
      {{m.email}}
    </div>
    <div class="text-subtitle-2 full-width mb-2">
      <v-icon class="mr-2" small>mdi-phone</v-icon>
      {{m.phone}}
    </div>
    <div class="text-caption full-width ">
      <v-icon class="mr-2" small>mdi-message</v-icon>
      {{m.message}}
    </div>
  </div>
</template>
<script>
export default {
  name: "Message",
  props: ["m"],
  data(){
    return {

    }
  }
  
}
</script>

<style lang="scss">

.admin-message {
  // overflow-x: hidden;
  position: relative;
  // max-height: 200px;
}

.notification {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 2;
}
  
</style>
