<template>
  <div :class="[$vuetify.theme.isDark ? 'grey darken-3' : 'grey lighten-1']">

    <div v-if="isLink" class="more-items-card rounded">
      <router-link  :to="{ name: routeName, params:{ id: item.id} }" class="full-width full-height d-flex justify-center align-center smoke--text text-decoration-none cursor--pointer">
        <div class="more-items-card-details text-center">{{item.title}}</div>
        <img :src="item.img"/>
      </router-link>
      <v-btn v-if="isAdmin" text absolute bottom right @click="updateProjectDisplay()" style="z-index: 3;">
        <v-icon v-if="item.display">mdi-eye</v-icon>
        <v-icon v-else>mdi-eye-off</v-icon>
      </v-btn>
    </div>


    <div v-else class="cursor--pointer more-items-card smoke--text rounded text-decoration-none">
      <div class="more-items-card-details text-center">{{item.title}}</div>
      <img :src="item.img"/>
      <v-btn v-if="isAdmin" text absolute bottom right @click="updateProjectDisplay()">
        <v-icon v-if="item.display">mdi-eye</v-icon>
        <v-icon v-else>mdi-eye-off</v-icon>
      </v-btn>
    </div>
  </div>

</template>
<script>
export default {
  name: 'ItemCard',
  props: ["item", "index", "isAdmin", "isLink", "routeName"],
  data: () => ({
    
  }),
  methods: {
    updateProjectDisplay(){
      let item = { ...this.item };
      item.display = !item.display;
      this.$store.dispatch('updatePortfolioItem', item);
    }
  }
    
}
</script>
<style lang="scss" scoped>
.more-items-card {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover img{
    opacity: 0.65;
  }

  img {
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: contain;
    position: absolute;
    opacity: 0.25;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
  }

  .more-items-card-details{
    z-index: 1;
  }
}



    
</style>