<template>
<v-container class="d-flex flex-row justify-center pa-0 pt-8">
    <v-col cols="12" xl="9" lg="11" md="12" sm="12" class="px-4 px-md-0">

      <v-row justify="space-between" align="center" class="compliment pa-2 full-width mx-0">
        <div class="text-title">Admin: Dillan Johnson</div>
        <div class="d-flex flex-row align-center justify-space-between">
          <div class="mr-4" style="position: relative;">
            <span v-if="newMessages > 0" class="warning smoke--text notification-amount">{{newMessages}}</span>
            <v-icon class="smoke--text" style="z-index: 0;">mdi-bell</v-icon>
          </div>
          <v-btn depressed outlined @click="adminLogout()">Log Out</v-btn>
        </div>
      </v-row>

      <v-row justify="start" align="center" class="mt-8 full-width mx-0">
        <template v-if="messages.length > 0">
          <v-col  v-for="(m, i) in messages" :key="i" cols="12" xl="4" lg="4" md="5" sm="6" xs="12">
            <Message :m="m" />
          </v-col>
        </template>
        <div v-else>No Messages.</div>
        

      </v-row>

      <v-divider class="primary my-8"></v-divider>

      <v-row justify="start" align="center" class="mt-8 full-width mx-0">
        <v-col v-for="(item, i) in adminPortfolioItems" :key="i" cols="12" xl="4" lg="4" md="5" sm="6" xs="12">
          <ItemCard :item="item" :index="i" :isAdmin="isAdmin" :isLink="true" routeName="ViewProject" />
        </v-col>
      </v-row>

  </v-col>
</v-container>
</template>
<script>
import { collection, query, orderBy, getDocs, updateDoc } from "firebase/firestore"; 
import { signOut } from "firebase/auth";
import firebase from "../../firebaseConfig.js";
import Message from "../components/Message.vue"
import ItemCard from "../components/ItemCard.vue"
export default {
  name: "Admin",
  metaInfo: {
    title: 'Admin | Dillan Johnson'
  },
  components: {
    Message,
    ItemCard
  },
  data() {
    return {
      messages: [],
    }
  },
  methods: {
    adminLogout(){
      signOut(firebase.auth).then(() => {
        this.$router.push({ path: "/" });
        this.$store.commit('setAdmin', false);
        this.$store.commit( 'alertUser', { show: true, message: "Admin Logged Out.", isGood: true, } );
      })
      .catch((err) => {
        this.$store.commit( 'alertUser', { show: true, message: "Failed To Log Out.", isGood: false, } );
        console.log("error logging out: ", err)
      })
    },
    async getLeads(){
      const leadsRef = collection(firebase.db, "messages");
      const q = query(leadsRef, orderBy("date", "desc"));
      const snapshot = await getDocs(q);
      // console.log("size", snapshot.size)
      snapshot.forEach((doc) => {
        if(!doc.data().opened){
          // console.log("trying to update...")
          updateDoc(doc.ref, { opened: true } );
        }
        // console.log(doc.data())
        this.messages.push(doc.data());
      })
    }
  },
  computed:{
    isAdmin(){
      return this.$store.state.isAdmin;
    },
    newMessages(){
      let total = 0;
      this.messages.forEach((message) => {
        if(!message.opened){
          total += 1;
        }
      })
      return total;
    },
    adminPortfolioItems(){
      return this.$store.state.adminPortfolioItems;
    }
  },
  created(){
    if(this.isAdmin){
      this.getLeads();
    }
    // else {
    //   this.$store.commit( 'alertUser', { show: true, message: "Hey. Don't Go Where You Don't Belong ☺️", isGood: false, } );
    //   this.$router.push({ path: "/" });
    // }
  }
  
}
</script>
<style lang="css">

.notification-amount {
  font-size: .75em;
  font-weight: bold;
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%,-40%);
  z-index: 1;
  text-align: center;
  vertical-align: center;
}
  
</style>